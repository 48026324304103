import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
import StrategicPlanGoalsChart from "./chart/chart-survey";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Together with the Leadership Team, PSI’s Board of Governors developed a Strategic Plan consisting of learning centred goals in the areas of Personalised, Thinking and Balanced.   In this second year of this plan, we continued our work to develop the school and its approaches in all of these areas.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/29b0f49ccd2502d934887edd03732d82/54de8/goals.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAC4jAAAuIwF4pT92AAAC0klEQVQoz02TTUgUYRjH91aXoA5JRBRSRFiBfUh06FJa1KXsFhlEdMoIiQgDpcTSQ6e0wqJ0JTXLJV0tMdTA/M5sLc39GNZ1d92ZndmdmZ15P2Z2Pt431lHpf3zgx/vyf36Pi0OYQ5iFKIk1lRJMCchiCWN2dZ6ACJumZtEkNiklTuh6XA4pWWZMAR29Y/Xvhl9F4PcUknSdx3gFIsO2oqruWYwHF+anpqcjkQgAQNO0HJwASDSNmVD8QlndvsPXd52vKRpVz83ghz6BBZDDGBgmpVTW9EgkwjAMx3GSJK3BSaxFZfXSjWd5h8r3nqrfXNq+YyC9/yu/8+VE3URYzurAMC1CgyJMpVLpdFqWZVVV174NKHH3TO4+cqewtKnkVt/dlrmrPjV/UMhr9p10Ty2kFYPYggTDQiaTyWCMdV03DGMDtmrfTuw503DwclvVi0mWj477l4qnta3ti1sednqDcULJl/FQQpT/zPkCwSAAQFEUh3cBaj59P1dU5t1e8qa81RtTxJGlwInR4Kamn9setPSHVmxKLJuKCmQYJhQKxWIxQRAwxjk4Yxv9P2IHrjwvqK28OFpbNd9RMfv69PCT4x2eY43d4Yyq2ZZl2eFlnuM4lmV5npckCUJICHGxCAs4e7O5Mf/etaONFcXeR2e9j29Pfqz2DQ4lllNYB6ZJbJLkZQghQkjTNF3XnW27WIjSWSOmqvc73QU1FYWN1YUN1ZUTAyNcPKXpKxBlbXtJFv1JLrjoj0aj1mps216TxDs0PBsIapT+SrLd/t/9zN9wRhb1LIfwKkwYMTnPx0P+QIhhnPfXCmMhau/uae3qcns8Y7M+YBE5a/JYYxFy9IRmThIOgbQoCoKgKIpTuGVZObh3+NuHvs9tn7o7erwBlktqOgsRt+42Mk2bEFaRRVESRVGWZUfPXGGO2ysqYAGMZZQEgA62AcNVPe3/7mHjMP4BUOa/wmDUZXcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "goals",
            "title": "goals",
            "src": "/static/29b0f49ccd2502d934887edd03732d82/c1b63/goals.png",
            "srcSet": ["/static/29b0f49ccd2502d934887edd03732d82/5a46d/goals.png 300w", "/static/29b0f49ccd2502d934887edd03732d82/0a47e/goals.png 600w", "/static/29b0f49ccd2502d934887edd03732d82/c1b63/goals.png 1200w", "/static/29b0f49ccd2502d934887edd03732d82/d61c2/goals.png 1800w", "/static/29b0f49ccd2502d934887edd03732d82/97a96/goals.png 2400w", "/static/29b0f49ccd2502d934887edd03732d82/54de8/goals.png 4961w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`In the goal area of `}<strong parentName="p">{`Personalised`}</strong>{`, some of the steps we have taken include a process to better review unit plans for personalisation, development of a `}<a parentName="p" {...{
        "href": "https://docs.google.com/document/d/1W4BMajkYPEZB-n-Nr9sWIPB3rxLuEyj2TvpF0SgMCTw/edit?usp=sharing"
      }}>{`philosophy of tech integration`}</a>{`, which is being revised based on learning from the pandemic experience, and faculty training on personalised learning, including a learner continuum and the differences between differentiated, individualised and personalised learning as outlined in `}<a parentName="p" {...{
        "href": "https://docs.google.com/document/d/1klj2PGzkoVBxvxNvT92u0UoW-VIZl9Qx_4n7DtKtqNs/edit"
      }}>{`this document`}</a>{`.`}</p>
    <p>{`In the `}<strong parentName="p">{`Thinking`}</strong>{` goal area, we have trained 89% of the faculty in the area of cultures of thinking, including through a November visit by Mark Church, co-author of Making Thinking Visible: How to Promote Engagement, Understanding, and Independence in All Learners, and consultant with Harvard Project Zero’s Making Thinking Visible and Cultures of Thinking initiatives worldwide.  We used MYP student criterion grades to assess student ability to transfer and apply knowledge and skills, and determined that 78.6% of students are showing at least an average of 5 or higher on indicated IB criterion, with a goal to have 80% of students at this level.`}</p>
    <p>{`With regard to the Goal of `}<strong parentName="p">{`Balanced`}</strong>{`, we continued our efforts to be a welcoming and friendly community, increased primary learner instruction in social emotional topics, adjusted our approach to co-curricular activity enrolment and worked to have students contributing positively to the digital world.  We found that prior to the pandemic, we had 84.7% of our students partaking in co-curricular activities.  We also found that our level of student counseling referrals for serious social and emotional issues increased by one student in each division; however, that can be explained both through increased enrolment and due to the stress caused by the pandemic situation.`}</p>
    <StrategicPlanGoalsChart mdxType="StrategicPlanGoalsChart" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      